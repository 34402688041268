<template>
  <Heading noDecoration text-align="center">Accommodation</Heading>
  <div
    class="accommodation"
    v-for="(accommodation, index) in accommodations"
    :key="index"
  >
    <div class="banner">
      <div class="banner-title">Do you need to book a stay?</div>
      Use the following code/link to book a hotel room for the conference stay
      <Tooltip
        :text="copied ? 'Copied to clipboard' : 'Click to copy'"
        class="mt-5"
        style="display: block"
        v-if="accommodation.booking_code"
      >
        <Button
          style="padding: 0; height: 56px; font-weight: 400"
          fullWidth
          @click="copyCode(config.hotelCode)"
        >
          {{ accommodation.booking_code }}
        </Button>
      </Tooltip>
      <Button
        class="mt-5"
        style="padding: 0; height: 56px; font-weight: 400"
        v-if="accommodation.booking_url"
      >
        <a
          class="booking-link"
          :href="accommodation.booking_url"
          target="_blank"
        >
          Booking link
        </a>
      </Button>
    </div>
    <div class="content" v-html="accommodation.description"></div>
  </div>
</template>

<script>
import config from "../../../public/config.json";
import Heading from "@/components/common/Heading";
import Tooltip from "@/components/common/Tooltip";
import Button from "@/components/common/Button";

export default {
  name: "AccommodationInfo",
  props: {
    accommodations: {
      type: Array,
      default: () => [],
    },
  },
  components: { Heading, Tooltip, Button },
  data: () => ({
    config,
    copied: false,
  }),
  computed: {},
  methods: {
    async copyCode(code) {
      console.log(code);
      try {
        await navigator.clipboard.writeText(code);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      } catch ($e) {
        console.log("Cannot copy");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

a {
  text-decoration: none;
}

.accommodation {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 47px;

  .banner {
    padding: 18px 23px;
    background-color: $light-gray;
    margin-bottom: 17px;

    &-title {
      font-family: $sarabun;
      font-size: 24px;
      font-weight: $sarabunSemibold;
      color: $dark-gray;
      text-transform: uppercase;
    }
  }

  @media (min-width: $media-sm) {
    flex-wrap: nowrap;
    .banner {
      order: 2;
      margin-bottom: 0;
      margin-left: 24px;
      min-width: 374px;
      height: fit-content;
    }
  }
  @media (min-width: $media-lg) {
    margin-top: 87px;

    .banner {
      padding: 53px 72px;
      min-width: 466px;
    }
  }
}
</style>
