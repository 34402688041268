<template>
  <Heading noDecoration text-align="center" class="title"
    >Update Profile</Heading
  >

  <div class="update-profile">
    <form class="profile--form" @submit.prevent="updateProfile">
      <template v-for="(formItem, index) in forms" :key="index">
        <FormToggler
          :form="formItem.form"
          :title="formItem.title"
          :open="!!formItem.open"
        >
          <template
            v-if="
              formItem.form === 'info' ||
              (!isSponsor && formItem.form === 'details')
            "
          >
            <div class="sponsors-logo" v-if="!editLogo && form.logo.value">
              <div
                class="sponsors-logo__card"
                :class="{ loading: logoLoading }"
              >
                <Preloader :loading="logoLoading" />
                <img
                  v-if="!logoLoading"
                  :src="form.logo.value"
                  alt="Sponsor logo"
                />
              </div>

              <Button
                class="mb-5"
                type="button"
                style="padding: 0; height: 56px; font-weight: 400"
                @click="editLogo = true"
                :disabled="logoLoading"
                >Edit {{ isSponsor ? "Logo" : "Profile" }}</Button
              >
            </div>
            <div class="logo-wrapper" v-if="!form.logo.value || editLogo">
              <Input
                v-model="form.logo.value"
                :error="form.logo.error"
                :name="name"
                :label="form.logo.label"
                :type="form.logo.type"
                :rows="form.logo.rows"
                :isMultiline="form.logo.isMultiline"
                @input="handleUploadingLogo($event, 'logo')"
              />
              <Button
                @click="editLogo = false"
                class="ml-2"
                style="padding: 0; height: 56px; font-weight: 400"
                >Cancel</Button
              >
            </div>
          </template>
          <template v-for="(key, index) in formItem.keys" :key="index">
            <Input
              v-if="key !== 'logo'"
              v-model="form[key].value"
              :error="form[key].error"
              :name="key"
              :label="form[key].label"
              :type="form[key].type"
              :rows="form[key].rows"
              :isMultiline="form[key].isMultiline"
            />
          </template>

          <template v-if="formItem.form === 'materials'">
            <span class="sponsor-booth">Booth Base Image</span>
            <p class="sponsor-booth-help">
              Please upload virtual booth background
            </p>
            <div
              class="sponsors-logo"
              v-if="!editBoothImage && form.booth_base_image.value"
            >
              <div
                class="sponsors-logo__card"
                :class="{ loading: boothImageLoading }"
              >
                <Preloader :loading="boothImageLoading" />

                <img
                  v-if="!boothImageLoading"
                  :src="form.booth_base_image.value"
                  alt="Sponsor Booth Image"
                />
              </div>

              <Button
                class="mb-5"
                type="button"
                style="padding: 0; height: 56px; font-weight: 400"
                :disabled="editBoothImage"
                @click="editBoothImage = true"
                >Edit Image</Button
              >
            </div>
            <div
              class="logo-wrapper"
              v-if="!form.booth_base_image.value || editBoothImage"
            >
              <Input
                v-model="form.booth_base_image.value"
                :error="form.booth_base_image.error"
                :name="name"
                :label="form.booth_base_image.label"
                :type="form.booth_base_image.type"
                :rows="form.booth_base_image.rows"
                :isMultiline="form.booth_base_image.isMultiline"
                @input="handleUploadingLogo($event, 'booth_base_image')"
              />
              <Button
                @click="editBoothImage = false"
                class="ml-2"
                style="padding: 0; height: 56px; font-weight: 400"
                >Cancel</Button
              >
            </div>
          </template>

          <template v-if="formItem.form === 'socialMedia'">
            <div class="social-media">
              <button
                type="button"
                class="add-social-media"
                @click="addSocialMediaField"
              >
                <div class="plus"></div>
              </button>

              <div
                class="input-wrapper"
                v-for="(socialMedia, index) in socialMedias"
                :key="index"
              >
                <div class="social">
                  <div class="icon" :class="socialMedia.icon"></div>
                </div>
                <Input
                  v-if="key !== 'logo'"
                  v-model="socialMedia.value"
                  :error="socialMedia.error"
                  :name="key"
                  :label="socialMedia.label"
                  :type="socialMedia.type"
                  @input="parseSocialLink(socialMedia, index)"
                />
                <button
                  v-if="socialMedias.length > 0"
                  class="remove-social-media"
                  @click="removeSocialMedia(index)"
                >
                  <div class="minus"></div>
                </button>
              </div>
            </div>
          </template>

          <BoothImages
            v-if="formItem.form === 'materials'"
            class="mt-5"
            :params="uploadParams"
            @uploaded="form.booth_resources.value = $event"
          />
        </FormToggler>
      </template>
      <div class="success-container" v-if="successMessage">
        <p>{{ successMessage }}</p>
      </div>
      <div class="error-container" v-if="Object.keys(errors).length">
        <h5>Errors:</h5>
        <ul>
          <li v-for="(error, index) of errors" :key="index">
            {{ error[0] }}
          </li>
        </ul>
      </div>
      <Button
        type="submit"
        class="mt-5"
        style="padding: 5px 20px; height: 56px; font-weight: 400"
        >Update</Button
      >
    </form>
  </div>
</template>

<script>
import Input from "@/components/common/Input";
import Heading from "@/components/common/Heading";
import Button from "@/components/common/Button";
import BoothImages from "./BoothImages";
import FormToggler from "./FormToggler";
import Preloader from "@/components/common/Preloader";
import {
  validateField,
  validateForm,
  clearError,
} from "@/assets/js/validation";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  name: "General",
  components: {
    Heading,
    Input,
    Button,
    BoothImages,
    FormToggler,
    Preloader,
  },
  props: {
    sponsor: {
      type: Object,
      default: () => {},
    },
    isSponsor: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    sponsor: {
      handler(data) {
        if (!this.isSponsor) {
          ["title", "description"].forEach((bioKey) => {
            this.form[bioKey].value = data.talk[bioKey];
          });
        }

        Object.keys(data).forEach((key) => {
          if (this.isSponsor && key === "social_media") {
            this.socialMedias = data.social_media.map((socmed) => {
              return {
                value: socmed.provider_id,
                error: "",
                label: "Social Media / Web link",
                isMultiline: true,
                type: "input",
                icon: `icon-${socmed.provider}`,
                provider: socmed.provider,
                provider_id: socmed.provider_id,
              };
            });
          }

          if (data[key] && Object.keys(this.form).includes(key)) {
            if (key === "bio") {
              if (data.bio.social_media && data.bio.social_media.length) {
                this.socialMedias = data.bio.social_media.map((socmed) => {
                  return {
                    value: socmed.provider_id,
                    error: "",
                    label: "Social Media / Web link",
                    isMultiline: true,
                    type: "input",
                    icon: `icon-${socmed.provider}`,
                    provider: socmed.provider,
                    provider_id: socmed.provider_id,
                  };
                });
              }
            }

            if (!this.isSponsor && key === "bio") {
              [
                "bio",
                "job_title",
                "organization",
                "first_name",
                "last_name",
              ].forEach((bioKey) => {
                this.form[bioKey].value = data.bio[bioKey];
              });
              this.form.logo.value = data.bio.image;
            } else {
              this.form[key].value = data[key];
            }
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      errors: {},
      logoLoading: false,
      boothImageLoading: false,
      editLogo: false,
      editBoothImage: false,
      loading: false,
      successMessage: "",
      socialMedias: [
        {
          value: "",
          error: "",
          label: "Social Media / Web link",
          isMultiline: true,
          type: "input",
          icon: "icon-web",
          provider: "",
        },
      ],
      form: {
        summary: {
          value: "",
          error: "",
          label: "Summary",
          isMultiline: true,
          type: "input",
          rows: 5,
        },
        description: {
          value: "",
          error: "",
          label: "Description",
          isMultiline: true,
          type: "input",
          rows: 5,
        },
        website: {
          value: "",
          error: "",
          label: "Website",
          type: "input",
        },
        first_name: {
          value: "",
          error: "",
          label: "First Name",
          type: "input",
        },
        last_name: {
          value: "",
          error: "",
          label: "Last Name",
          type: "input",
        },
        job_title: {
          value: "",
          error: "",
          label: "Job Title",
          type: "input",
        },
        bio: {
          value: "",
          error: "",
          label: "Bio",
          type: "input",
          isMultiline: true,
          rows: 5,
        },
        title: {
          value: "",
          error: "",
          label: "Title",
          type: "input",
        },
        organization: {
          value: "",
          error: "",
          label: "Organization",
          type: "input",
        },
        email: {
          value: "",
          error: "",
          label: "Email",
          type: "input",
        },
        phone: {
          value: "",
          error: "",
          label: "Mobile Phone",
          type: "input",
        },
        notes: {
          value: "",
          error: "",
          isMultiline: true,
          label: "Notes",
          type: "input",
          rows: 5,
        },
        logo: {
          value: "",
          error: "",
          label: "Logo",
          type: "file",
        },
        booth_base_image: {
          value: "",
          error: "",
          label: "Logo",
          type: "file",
        },
        booth_resources: {
          value: [],
        },
      },
    };
  },
  computed: {
    profileForm() {
      return Object.keys(this.form).reduce(
        (prev, curr) => {
          if (curr === "logo" && !this.isSponsor) {
            prev.data.image = this.form[curr].value;
          } else {
            prev.data[curr] = this.form[curr].value;
          }
          return prev;
        },
        {
          event_id: this.$route.params.id,
          form_type: this.profilePath,
          data: {
            name: this.sponsor.name,
            social_media: this.socialMediaValues,
            image: null,
          },
        }
      );
    },
    sponsorForm() {
      return Object.keys(this.form).reduce(
        (prev, curr) => {
          if (curr === "logo" && !this.isSponsor) {
            prev.image = this.form[curr].value;
          } else {
            prev[curr] = this.form[curr].value;
          }
          return prev;
        },
        { social_media: this.socialMediaValues }
      );
    },
    forms() {
      return this.formsRaw.filter((form) => {
        if (this.isSponsor) {
          return (
            (form.isSponsorOnly || form.isSponsorOnly === undefined) &&
            !form.isSpeakerOnly
          );
        } else {
          return form.isSpeakerOnly || form.isSponsorOnly === undefined;
        }
      });
    },
    formsRaw() {
      return [
        {
          title: "Company Info",
          form: "info",
          open: true,
          keys: ["summary", "description", "website", "logo"],
          isSponsorOnly: true,
        },
        {
          title: this.isSponsor ? "Contact Details" : "Profile",
          form: "details",
          keys: ["logo", "first_name", "last_name", "email", "phone"],
          open: !this.isSponsor,
        },
        {
          title: "Social Media",
          form: "socialMedia",
        },
        {
          title: "Bio details",
          form: "bioMedia",
          keys: ["bio", "job_title", "organization"],
          isSpeakerOnly: true,
        },
        {
          title: "Talk details",
          form: "talkDetails",
          keys: ["title", "description"],
          isSpeakerOnly: true,
        },
        {
          title: "Materials",
          form: "materials",
          isSponsorOnly: true,
        },
        {
          title: "Notes",
          form: "notes",
          keys: ["notes"],
        },
      ];
    },
    uploadParams() {
      const { id, token } = this.$route.params;

      return { id, token };
    },

    profilePath() {
      return this.isSponsor ? "draft_sponsor" : "draft_speaker";
    },
    updateProfilePath() {
      return this.isSponsor ? "sponsor" : "profile";
    },
    kitPath() {
      return this.isSponsor ? "exhibitor_kit" : "speaker_kit";
    },
    socialMediaValues() {
      return this.socialMedias.map((item) => {
        const { value, provider } = item;
        return {
          provider,
          provider_id: value,
        };
      });
    },
  },
  methods: {
    ...mapActions(["GET", "POST"]),
    validateField,
    validateForm,
    clearError,
    addSocialMediaField() {
      this.socialMedias.push({
        value: "",
        error: "",
        label: "Social Media / Web link",
        type: "input",
        icon: "icon-web",
        provider: "",
      });
    },
    inputAndFile(field) {
      return field.type === "input"
        ? true
        : field.type !== "file" && typeof field.value !== "string";
    },
    handleUploadingLogo(e, key) {
      if (key === "logo") {
        this.logoLoading = true;
        this.editLogo = false;
      } else {
        this.boothImageLoading = true;
        this.editBoothImage = false;
      }
      this.POST({
        route: "/file/",
        data: {
          file_name: e.target.files[0].name,
          workflows: this.isSponsor ? [] : ["profile_image"],
        },
      }).then((res) => {
        const keyFields = [
          "AWSAccessKeyId",
          "key",
          "policy",
          "signature",
          "x-amz-security-token",
        ];
        const { url, fields } = res.data;
        const formData = new FormData();
        for (const key of keyFields) {
          formData.set(key, fields[key]);
        }
        formData.set("file", e.target.files[0]);
        axios
          .post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            if (key === "logo") {
              this.editLogo = false;
              this.logoLoading = false;
            } else {
              this.editBoothImage = false;
              this.boothImageLoading = false;
            }
            this.logo = null;
            this.form[key].value = `${url}${fields.key}`;
          })
          .then(() => {
            this.GET({
              route: `/file/${res.data.id}/run_workflows`,
            })
              .then(() => {
                console.log("workflows run");
                console.log(res);
              })
              .catch((e) => {
                console.log({ ERROR: e });
              });
          });
      });
    },
    updateProfile() {
      this.loading = true;
      const { id, token } = this.uploadParams;
      this.errors = {};
      this.POST({
        route: `${this.isSponsor ? "" : "/public"}/event/${id}/${
          this.kitPath
        }/${token}/${this.updateProfilePath}`,
        data: this.sponsorForm,
      })
        .then(() => {
          this.POST({
            route: `/form/${this.profilePath}`,
            data: this.profileForm,
          })
            .then(() => {
              this.successMessage = "Your changes has been submitted!";
              this.loading = false;
            })
            .catch((e) => {
              console.log({ ERROR: e });
            });
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            this.errors = err.response.data.errors;
          }
          this.POST({
            route: `form/${this.profilePath}/error`,
            data: {
              form_type: this.profilePath,
              event_id: this.$route.params.id,
              data: err,
            },
          });
        });
    },

    parseSocialLink(item, counter) {
      let linkObj = this.socialMedias[counter];
      let id = linkObj.value;

      if (id.trim().length) {
        linkObj.value = id.split("com/")[1];
        linkObj.provider = linkObj.value;
        if (id.includes("htt")) {
          linkObj.value = id.split("//")[1].split(".com")[0];
          linkObj.provider = linkObj.value;
        } else {
          linkObj.value = id.split(".com")[0];
          linkObj.provider = linkObj.value;
        }
        if (id.includes("www")) {
          linkObj.value = id;
          linkObj.provider = id;
        }
        if (id.includes("linkedin")) {
          if (id.includes("company")) {
            linkObj.value = id.split("company/")[1].split("/")[0];
            linkObj.icon = "icon-linkedin_company";
            linkObj.provider = "linkedin_company";
          } else {
            linkObj.value = id.split("in/")[1].split("/")[0];
            linkObj.icon = "icon-linkedin";
            linkObj.provider = "linkedin";
          }
        } else if (id.includes("instagram")) {
          linkObj.value = id.split("instagram.com/")[1].split("/")[0];
          linkObj.icon = "icon-instagram";
          linkObj.provider = "instagram";
        } else if (id.includes("twitter")) {
          linkObj.value = id.split("twitter.com/")[1].split("/")[0];
          linkObj.icon = "icon-twitter";
          linkObj.provider = "twitter";
        } else if (id.includes("spotify")) {
          linkObj.value = id.split("spotify.com/")[1];
          linkObj.icon = "icon-spotify";
          linkObj.provider = "spotify";
        } else if (id.includes("facebook")) {
          linkObj.value = id.split("facebook.com/")[1].split("/")[0];
          linkObj.icon = "icon-facebook";
          linkObj.provider = "facebook";
        } else if (id.includes("github")) {
          linkObj.value = id.split("github.com/")[1].split("/")[0];
          linkObj.icon = "icon-github";
          linkObj.provider = "github";
        } else if (id.includes("reddit")) {
          linkObj.value = id.split("reddit.com/r/")[1].split("/")[0];
          linkObj.icon = "icon-reddit";
          linkObj.provider = "reddit";
        } else if (id.includes("stackoverflow")) {
          linkObj.value = id.split("stackoverflow.com/")[1];
          linkObj.icon = "icon-stackoverflow";
          linkObj.provider = "stackoverflow";
        }
      }
    },
    removeSocialMedia(index) {
      this.socialMedias.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.update-profile {
  max-width: 600px;
  margin: auto;
  margin-top: 80px;
}

.logo-wrapper {
  display: flex;
  margin-top: 50px;
}

.sponsor-booth {
  font-size: 18px;
  display: block;
  color: $dark-gray;
  text-transform: uppercase;
  width: 100%;
}

.sponsor-booth-help {
  font-size: 13px;
  display: block;
  color: $dark-gray;
  width: 100%;
}

.success-container {
  padding: 8px;
  text-align: center;
  border: 2px solid rgba(29, 28, 28, 0.9);
  margin-bottom: 10px;
}
.error-container {
  padding: 8px;
  border: 2px solid rgba(223, 7, 7, 0.9);
  margin-bottom: 10px;

  h5 {
    font-size: 24px;
    margin-left: 24px;
    color: $dark-gray;
  }
}

.sponsors-logo {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__container {
    margin-top: 50px;
    display: block;
  }

  &__card {
    background: #ffffff;
    border-radius: 10px !important;
    height: 140px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    &.loading {
      background-color: transparent;
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 10px;
    }

    img {
      max-width: 120px;
      max-height: 120px;
    }
  }
}

@media (min-width: $media-xs) {
  .sponsors-logo {
    &__card {
      background: #ffffff;
      border-radius: 10px !important;
      height: 200px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 160px;
        max-height: 160px;
      }
    }
  }
}

@media (min-width: $media-lg) {
  .sponsors-logo {
    &__card {
      background: #ffffff;
      border-radius: 10px !important;
      height: 250px;
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 200px;
        max-height: 200px;
      }
    }
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
  position: relative;
}

.icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: all 0.3s;
  margin-top: -30px;

  &.icon-stackoverflow {
    background-image: url("~@/assets/img/icons/social/stackoverflow.svg");
  }
  &.icon-google {
    background-image: url("~@/assets/img/icons/social/google.svg");
  }
  &.icon-facebook {
    background-image: url("~@/assets/img/icons/social/facebook.svg");
  }
  &.icon-instagram {
    background-image: url("~@/assets/img/icons/social/instagram.svg");
  }
  &.icon-spotify {
    background-image: url("~@/assets/img/icons/social/spotify.svg");
  }
  &.icon-reddit {
    background-image: url("~@/assets/img/icons/social/reddit.svg");
  }
  &.icon-web {
    background-image: url("~@/assets/img/icons/social/web.svg");
  }
  &.icon-youtube {
    background-image: url("~@/assets/img/icons/social/youtube.svg");
  }
  &.icon-twitter {
    background-image: url("~@/assets/img/icons/social/twitter.svg");
  }
  &.icon-linkedin {
    background-image: url("~@/assets/img/icons/social/linkedin.svg");
  }
}

.add-social-media {
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: $dark-gray;
  border-radius: 8px;
  padding: 4px 12px;
  margin-bottom: 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;

  .plus {
    position: relative;
    width: 20px;
    height: 20px;
    background: transparent;
  }

  .plus:before,
  .plus:after {
    content: "";
    position: absolute;
    background: $dark-gray;
  }

  .plus:before {
    left: 50%;
    top: 4px;
    bottom: 4px;
    width: 2px;
    transform: translateX(-50%);
  }

  .plus:after {
    top: 50%;
    left: 4px;
    right: 4px;
    height: 2px;
    transform: translateY(-50%);
  }
}

.remove-social-media {
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: $dark-gray;
  border-radius: 8px;
  padding: 4px 12px;
  margin-bottom: 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;

  .minus {
    position: relative;
    width: 20px;
    height: 20px;
    background: transparent;
  }

  .minus:before,
  .minus:after {
    content: "";
    position: absolute;
    background: $dark-gray;
  }

  .minus:before {
    left: 50%;
    bottom: 4px;
    width: 2px;
    transform: translateX(-50%);
  }

  .minus:after {
    top: 50%;
    left: 4px;
    right: 4px;
    height: 2px;
    transform: translateY(-50%);
  }
}
</style>
